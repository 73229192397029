import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import {
  API_BASE_URL,
  APP_PREFIX_PATH,
  LIVE_URL,
  REACT_APP_DEV_MODE,
  TEST_URL,
} from "configs/AppConfig";

import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";

import classes from "./VerifyEmailForm.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, message, Button, Form, notification } from "antd";
import { useTranslation } from "react-i18next";
import { auth, googleAuthProvider } from "configs/FirebaseConfig";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
} from "firebase/auth";
import getErrorMessage from "helpers/getErrorMessage";
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const rules = {
  name: [
    {
      required: true,
      message: "Required",
    },
  ],
  email: [
    {
      required: true,
      message: "Required",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  required: [
    {
      required: true,
      message: "Required",
    },
  ],
};

const VerifyEmailForm = ({ setShowSignInDetails }) => {
  const { direction } = useSelector((state) => state.language);
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showLoginWithEmail, setShowLoginWithEmail] = useState(false);
  const [loadingEmailLoginLink, setLoadingEmailLoginLink] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const sendLoginWithEmailLink = () => {
    emailForm.validateFields().then(async (values) => {
      setLoadingEmailLoginLink(true);
      const actionCodeSettings = {
        url:
          REACT_APP_DEV_MODE == "Dev" //TODO
            ? TEST_URL
            : LIVE_URL, //redirect url after resetting password
        handleCodeInApp: true,
      };

      try {
        await sendSignInLinkToEmail(auth, values.email, actionCodeSettings);
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", values.email);
        notification.success({
          message: t("success"),
          description:
            // "Verification sent to your provided email.  If you cannot find the email, please check your SPAM or JUNK folder, and unblock Oumuamua News.",
            "Email verification notification has been sent. Please also check your spam folder and unblock Oumuamua.news safe sender list",
          style: { direction },
        });
        dispatch(modalActions.toggleModal());
        setLoadingEmailLoginLink(false);
      } catch (error) {
        console.error("Error sending email link:", error);
        notification.error({
          message: "Error",
          description: getErrorMessage(error.code),
        });
        setLoadingEmailLoginLink(false);
      }
    });
  };

  const goBack = () => {
    if (showForgotPassword) {
      setShowForgotPassword(false);
      form.resetFields();
    }
    if (showLoginWithEmail) {
      setShowLoginWithEmail(false);
      emailForm.resetFields();
    }
  };

  return (
    <div dir={direction}>
      <div className={classes.signinForm}>
        <h1>{t("verify_email")}</h1>
        <Form
          layout="vertical"
          form={emailForm}
          name="filter_form"
          className="ant-advanced-search-form w-100 connectForm"
        >
          <Form.Item
            label={t("enter_email")}
            name="email"
            rules={rules.email}
            style={{ marginTop: "20px" }}
          >
            <Input
              placeholder={t("enter_email")}
              // className="signInInput"
              // value={email}
              // onChange={(e) => setEmail(e.target.value)}
              // onBlur={checkEmailValid}
            />
          </Form.Item>
        </Form>
        <div className={classes.buttonContainer}>
          {/* <button className={classes.submitButton} onClick={() => goBack()}>
              {t("go_back")}
            </button> */}
          <button
            className={classes.submitButton}
            disabled={loadingEmailLoginLink}
            onClick={() => setShowSignInDetails(true)}
          >
            {t("go_back")}
          </button>
          <button
            className={classes.submitButton}
            disabled={loadingEmailLoginLink}
            onClick={() => sendLoginWithEmailLink()}
          >
            {loadingEmailLoginLink && (
              <LoadingOutlined style={{ width: "15px", marginRight: "5px" }} />
            )}
            {t("submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailForm;
