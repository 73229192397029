
export const ConstantsDataKey = 'constants';
export const SettingsDataKey = 'settings';
export const TagsDataKey = 'tags';
export const CategoriesDataKey = 'categories';
export const ArticlesDataKey = 'articles';
export const ArticlesCustomerDataKey = 'articlesByCustomer';
export const ArticleDetailDataKey = 'articleDetail';
export const CommentsDataKey = 'comments';
export const GalleryDataKey = 'gallery';
export const PolicyDataKey = 'policy';

